.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: #ececec;
  overflow: hidden;
}

.offlineMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  color: #ececec;
}

.cameraView {
  flex: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  height: 100%;
}

.camerasView {
  display: grid;
  gap: 0.25rem;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.camerasView[data-grid-size="1"] {
  max-width: 1400px;
}

.camerasView[data-grid-size="4"] {
  max-width: 1400px;
  grid-template-columns: repeat(2, 1fr);
}

.camerasView[data-grid-size="9"] {
  max-width: 1400px;
  grid-template-columns: repeat(3, 1fr);
}

.camerasView[data-grid-size="16"] {
  max-width: 1400px;
  grid-template-columns: repeat(4, 1fr);
}

.camera {
  height: auto;
  aspect-ratio: 16/9;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.controls {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.leftControl {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.centerControl {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-top: 1rem;
}

.pageControl {
  padding-left: 3rem;
  width: 100%;
}

.navigationControls {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 20px;
}

.navigationControls button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #100f17;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.navigationControls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.navigationControls button:hover:not(:disabled) {
  background-color: #2a2a2a;
}

.grid_controls {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #100f17;
  padding: 0.5rem;
  border-radius: 3rem;
}

.grid_controls button {
  display: flex;
  background-color: #100f17;
  border: none;
  color: #fefefe;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 4rem;
  height: 3rem;
  width: 3rem;
  cursor: pointer;
}

.grid_controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.grid_controls button:hover:not(:disabled) {
  background-color: #2a2a2a;
}

@media only screen and (max-width: 1023px) {
  .cameraView {
    flex: 10;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .camerasView {
    grid-template-columns: repeat(1, 1fr);
  }

  .camerasView[data-grid-size="4"] {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
  }

  .camerasView[data-grid-size="9"] {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
  }

  .camerasView[data-grid-size="16"] {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
  }

  .camera {
    height: 33vh;
    aspect-ratio: 4 / 3;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
