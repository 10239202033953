.devices-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin-left: 8%;
  }
  
  .devices-title {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
  }

  .devices-title > h1 {
    font-size: 2rem;
    font-weight: 600;
  }

  .devices-title > p {
    font-size: 1rem;
    font-weight: 300;
  }
  
  .add-device-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #25347B; /* Ajuste a cor do botão conforme necessário */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 1rem;
  }
  
  .add-device-button:hover {
    background-color: #0056b3; /* Cor do botão ao passar o mouse */
  }
  
  @media (max-width: 768px) {
    .devices-bar {
      margin-left: 0px;
    }
  }