.conv_header {
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-left: 5rem;
  margin-top: 3rem;
  font-size: 18px;
  margin-bottom: 1.5rem;
}

.conv_item {
  cursor: pointer;
}

.conv_body {
  width: 96%;
  margin: auto;
  background-color: #1a1a1a;
  padding: 1rem;
  border-radius: 0.75rem;
}
