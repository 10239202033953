.outerContainer {
  display: flex;
}

.contentColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #ededed;
  padding: 1rem 1.5rem;
}

.line {
  flex: 1;
  display: flex;
  margin-bottom: 1.75rem;
}

.lastLine {
  display: flex;
  height: 518px;
  min-height: 518px;
  margin-bottom: 1rem;
}

.column {
  flex: 1; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row; 
  margin: auto;
  padding: 0 0.875rem;
}

.subColumn {
  flex: 1; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

@media (max-width: 768px) {
  .outerContainer {
    flex-direction: column;
  }

  .fixedColumn {
    width: 100%;
    height: auto;
  }

  .contentColumn {
    width: 100%;
  }

  .container {
    padding: 1rem;
  }

  .line {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .lastLine {
    flex-direction: column;
    height: auto;
  }

  .column {
    margin: 0.5rem 0;
    padding: 0;
    flex-direction: column;
  }
}
