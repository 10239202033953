.card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  row-gap: 2.25rem;
  height: 100%;
  padding-left: 1.5rem;
}

.cameraBox,
.offlineBox,
.streamingBox {
  display: flex;
  flex-direction: row;
  background-color: #1c1c1c;
  min-height: 28%;
  gap: 1rem;
  padding: 1rem 0;
}

.cameraBox {
  border-left: 4px solid #129bff;
}

.offlineBox {
  border-left: 4px solid #7f2424;
}

.streamingBox {
  border-left: 4px solid #25347b;
}

.textLeft {
  min-width: 25%;
  max-width: 25%;
  font-size: 1.75rem;
  font-weight: 700;
  text-align: center;
  height: 100%;
  align-content: center;
}

.textRight {
  min-width: 55%;
  max-width: 55%;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  height: 100%;
  align-content: center;
}