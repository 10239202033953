.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-left: 1.5rem;
}

.containersofia {
  display: flex;
  flex-direction: column;
  background: linear-gradient(145deg, #1c1c1c, #001d42);
  width: 100%;
  height: 280px;
  min-width: 400px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}

.view {
  position: relative;
  width: var(--side);
  height: var(--side);
  perspective: 400px;
}

.plane {
  width: var(--side);
  height: var(--side);
  transform-style: preserve-3d;
}

.plane.main {
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  transform: rotateX(60deg) rotateZ(-30deg);
  animation: rotate 20s infinite linear;
}

.circle {
  width: var(--side);
  height: var(--side);
  position: absolute;
  transform-style: preserve-3d;
  border-radius: 80%;
  box-shadow: 0 0 10px #25347b, inset 0 0 60px #25347b;
  transition: transform 1s ease;
}

.circle::before,
.circle::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 10%;
  height: 10%;
  border-radius: 100%;
  background: #25347b;
  box-shadow: 0 0 10px 2px #25347b;
}

.circle::before {
  transform: translateZ(calc(var(--l) * -1));
}

.circle::after {
  transform: translateZ(var(--l));
}

.circle:nth-child(1) {
  transform: rotateZ(calc(1 * 72deg)) rotateX(63.435deg);
}

.circle:nth-child(2) {
  transform: rotateZ(calc(2 * 72deg)) rotateX(63.435deg);
}

.circle:nth-child(3) {
  transform: rotateZ(calc(3 * 72deg)) rotateX(63.435deg);
}

.circle:nth-child(4) {
  transform: rotateZ(calc(4 * 72deg)) rotateX(63.435deg);
}

.circle:nth-child(5) {
  transform: rotateZ(calc(5 * 72deg)) rotateX(63.435deg);
}

.circle:nth-child(6) {
  transform: rotateZ(calc(6 * 72deg)) rotateX(63.435deg);
}

@keyframes rotate {
  0% {
    transform: rotateX(0) rotateY(0) rotateZ(0);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
  }
}
