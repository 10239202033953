.login_Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    color: var(--font-color);
}

.login_Form {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    background-color: var(--secondary-color);
}

.login_Form > img {
    margin-bottom: 2rem;
    width: 250px;
}

.login_Form > form {
    display: flex;
    width: 80%;
    gap: 0.5rem;
    flex-direction: column;
}

.login_Form > form > h2 {
    font-size: 2rem;
    font-weight: 600;
}

.login_Form > form > label {
    font-size: 0.8rem;
    opacity: 0.5;
}

.login_Form > form > input {
    padding: 1rem;
    background-color: var(--primay-color);
    border: 1px solid var(--primary-gray);
    border-radius: 0.5rem;
}

.login_Form > form > button {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 1rem;
    margin-top: 3rem;
    cursor: pointer;
    border-radius: 0.5rem;
    font-size: 1rem;
    color: var(--secondary-color);
    transition: background-color 0.3s, color 0.3s; 
}

.login_Form > form > button:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.login_Form > form > button:focus {
    background-color: var(--secondary-color);
}

.login_Footer {
    position: fixed;
    bottom: 0px;
    color: var(--font-color);
    left: 0px;
    width: 50%;
    font-size: 0.8rem;
}

.login_Footer_Content {
    padding: 1.5rem;
    display: flex;
    align-items: center;
    filter: opacity(0.5);
    justify-content: space-between;
}

.login_Banner {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary-color);
    height: 100vh;
    flex-direction: column;
    text-align: center;
    background-size: cover; 
    background-position: center; 
    transition: background-image 0.5s ease-in-out;
}

.login_Banner > h1 {
    text-align: center;
    font-weight: 500;
}

.login_Banner > p {
    font-weight: 300;
    width: 70%;
}

.login_Link {
    color: var(--primary-color);
    font-weight: 600
}

.login_Link:hover {
    text-decoration: underline;
    cursor: pointer;;
}

.login_Contact {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.error-message {
    color: red;
    background-color: #ffe6e6;
    border: 1px solid red;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 1rem;
    font-size: 0.8rem;
    white-space: pre-line;
}

  .loading-button {
    width: 1em;
    height: 1em;
}

@media only screen and (max-width: 768px) {
    .login_Banner {
        display: none;
    }

    .login_Form {
        width: 100%;
    }

    .login_Form > form {
        width: 70%;
    }

    .login_Footer {
        width: 100%;
    }
}