.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(145deg, #1c1c1c, #001d42);
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
  color: #fff;
}

a {
  text-decoration: none;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.username {
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

.email {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  padding: 0.75rem 2.5rem;
  border: 2px solid #364ab2;
  border-radius: 0.5rem;
  background: transparent;
  color: #364ab2;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.button:hover {
  background-color: rgba(54, 74, 178, 0.1);
  border: 2px solid #ededed;
  color: #ededed;
}