* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.reports_container {
  color: #dedede;
}

.reports_margin {
  margin-top: 1.125rem;
}

.reports_header {
  display: flex;
  justify-content: flex-start;
  margin-left: 5rem;
}

.reports_select select {
  margin-left: 8rem;
  margin-top: 1.25rem;
  background-color: #132430;
  color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}
