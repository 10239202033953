.content_items {
  overflow: auto;
  width: 100%;
}

.card_devices {
  display: flex;
  flex-direction: column;
  background-color: #1c1c1c;
  color: #d3d3d3;
  width: 100%;
  border-radius: 1rem;
  padding: 2rem;
}

.card_devices p {
  background-color: #242424;
  padding: 0.5rem;
  border-radius: 1rem;
  width: fit-content;
  padding: 0.5rem 0.75rem;
}

.devices_Table {
  width: 100%;
  margin-bottom: 20px;
}

.devices_Table th,
.devices_Table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 0.1rem dashed #6e6e6e;
  white-space: nowrap;
}

.empty_device {
  color: #1c1c1c;
}

.status_online .online_text {
  color: #34c236;
  border: 0.5px solid #34c236;
  padding: 0.5rem;
  border-radius: 1.5rem;
  font-size: 0.8rem;
}

.status_offline .offline_text {
  color: #c24e34;
  border: 0.5px solid #c24e34;
  padding: 0.5rem;
  border-radius: 1.5rem;
  font-size: 0.8rem;
}

.status_streaming .streaming_text {
  color: #17a2b8;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.6rem;
}

.data_Button {
  padding: 0.75rem 2.5rem;
  border: 2px solid #364ab2;
  border-radius: 0.5rem;
  background: transparent;
  color: #364ab2;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.data_Button:hover {
  background-color: rgba(54, 74, 178, 0.1);
  border: 2px solid #ededed;
  color: #ededed;
}

/* Media query para dispositivos menores */
@media (max-width: 768px) {
  .devices_Actions {
    justify-content: center;
  }

  .devices_Table th,
  .devices_Table td {
    padding: 10px;
    font-size: 12px;
  }
}

@media (max-width: 1000px) {
  .card_devices {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}
