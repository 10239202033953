.conv_header {
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-left: 5rem;
  margin-top: 3rem;
  font-size: 18px;
  margin-bottom: 1.5rem;
}

.conv_item {
  cursor: pointer;
}

.conv_body {
  width: 96%;
  margin: auto;
  background-color: #1a1a1a;
  padding: 1rem;
  border-radius: 0.75rem;
}

.vis_container {
    width: 100%;
}

.vis_content {
  display: flex;
  width: 100%;
  gap: 4rem;
}

.vis_left_content {
  width: 50%;
}


.vis_header {
  display: flex;
}

.vis_title {
  max-width: fit-content;
  padding: 0.75rem 5rem 0.75rem 0.825rem;
  background-color: #313131;
  color: #dedede;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.vis_display_tv {
  margin-left: 4rem;
  font-size: 2rem;
  font-weight: 500;
  color: #129bff;
}

.vis_display_tc {
  margin-left: 4rem;
  font-size: 2rem;
  font-weight: 500;
  color: #c8008b;
}

.date_picker {
    display: flex;
    gap: 2rem;
    margin-top: 0.875rem;
    margin-bottom: 3.75rem;
}

.vis_content_data {
    margin-top: 4.325rem;
    min-width: 100%;
    height: fit-content;
    margin-bottom: 1.5rem;
}

.vis_content_data2 {
    margin-top: 4.325rem;
    min-width: 100%;
    height: fit-content;
}