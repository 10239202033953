.devices_Filter {
  margin-bottom: 20px;
  text-align: right;
}

.filter-dropdown {
  position: absolute;
  top: 50px; /* Ajuste conforme necessário */
  right: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: none; /* Inicialmente oculto */
  z-index: 100; /* Ajustado para ficar acima de outros elementos */
  width: 200px; /* Largura fixa do dropdown */
  color: black;
}

.filter-dropdown.active {
  display: block; /* Mostrar quando ativo */
}

.filter-dropdown label {
  display: block;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-dropdown label:hover {
  background-color: #f0f0f0; /* Cor de fundo ao passar o mouse */
}

.filter-dropdown label input[type="checkbox"] {
  margin-right: 10px;
}

.filter-button {
  position: absolute;
  top: 0; /* Ajuste conforme necessário */
  right: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.filter-button:hover {
  background-color: #0056b3;
}

/* Estilo para o ícone do filtro */
.filter-button::after {
  content: "\f0b0"; /* Código Unicode do ícone de filtro (pode ser ajustado conforme o ícone utilizado) */
  font-family: "Font Awesome 5 Free"; /* Fonte do ícone (exemplo com Font Awesome) */
  margin-left: 10px;
}

.filter-button:hover::after {
  color: #fff; /* Cor do ícone ao passar o mouse */
}

.filter-button:focus {
  outline: none; /* Remover contorno ao focar */
}

/* Estilos para a seção de dispositivos */
.devices {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem;
  font-family: Arial, sans-serif;
  margin-left: 2rem;
  margin-right: 2rem;
  position: relative;
  background-color: #1c1c1c;
  color: #d3d3d3;
  border-radius: 1rem;
}

.devices_Content {
  width: 100%;
  max-width: 100%; /* Modificado para adaptar a largura total */
  padding-right: 1rem;
  background-color: #1c1c1c;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  overflow-x: auto; /* Adicionado para rolagem horizontal em telas menores */
}

.devices_Actions {
  display: flex;
  justify-content: flex-end; /* Corrigido para alinhar à direita */
  margin: 1rem 0;
}

.devices_Actions button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.3s ease;
}

.start_Button {
  background-color: #28a745;
}

.finish_Button {
  background-color: #dc3545;
}

.edit_Button {
  background-color: #ffc107;
}

.delete_Button {
  background-color: #343a40;
}

.devices_Table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: fixed; /* Define o layout da tabela como fixo */
}

.devices_Table th,
.devices_Table td {
  padding: 1rem 0;
  text-align: left;
  border-bottom: 0.1rem dashed #6e6e6e;
  white-space: nowrap; /* Evita que o texto quebre em várias linhas */
  overflow: hidden;
  text-overflow: ellipsis; /* Mostra "..." para texto que transborda */
}

.devices_Selector {
  width: 20px;
}

/* Estilos para status */
.status-online .online-text {
  color: #28a745;
  border: 1px solid #28a745;
  padding: 0.5rem;
  border-radius: 1.5rem;
}

.status-offline .offline-text {
  color: #d92d20;
  border: 1px solid #d92d20;
  padding: 0.5rem;
  border-radius: 1.5rem;
}

.status-streaming .streaming-text {
  color: #17a2b8;
}

/* Estilos para paginação */
.pagination {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.pagination button {
  padding: 1rem;
  border: 1px solid #d0d5dd;
  border-radius: 1rem;
  display: flex;
  font-weight: 500;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.3s ease;
}

/* Estilos para mensagens de feedback */
.loading-message,
.error-message,
.success-message {
  margin: 20px 0;
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
}

.loading-message {
  background-color: var(--primary-blue);
  color: #fff;
}

.error-message {
  background-color: #dc3545;
  color: #fff;
}

.success-message {
  background-color: #28a745;
  color: #fff;
}

.edit-form-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.edit-form-item {
  display: flex;
  flex-direction: column;
}

.edit-button-container {
  margin: 1em;
}

.confirm_Button,
.cancel_Button {
  padding: 0.5em 1em;
  margin: 0.5em;
}

.device_modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_content {
  background-color: #1c1c1c;
  padding: 20px;
  border-radius: 1rem;
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Media query para dispositivos menores */
@media (max-width: 768px) {
  .devices {
    margin-left: 0;
    padding: 10px;
  }

  .devices_Content {
    padding: 10px;
  }

  .devices_Actions {
    justify-content: center;
  }

  .devices_Actions button {
    padding: 8px 16px;
    font-size: 12px;
  }

  .devices_Table th,
  .devices_Table td {
    padding: 10px;
    font-size: 12px;
  }

  .pagination button {
    padding: 8px 12px;
    font-size: 12px;
  }
}
