.card_alert_list {
  display: flex;
  flex-direction: column;
  background-color: #1c1c1c;
  color: #d3d3d3;
  width: 100%;
  border-radius: 1rem;
  padding: 2rem;
}

.card_alert_list p {
  background-color: #242424;
  border-radius: 1rem;
  width: fit-content;
  padding: 0.5rem 0.75rem;
}

.alert_list_table {
  width: 100%;
  text-align: left;
}

.alert_list_table th,
.alert_list_table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 0.1rem dashed #6e6e6e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alert_list_navigation {
  margin-top: 2em;
  display: flex;
  padding-bottom: 0.2rem;
}

.alert_list_navigation button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: var(--secondary-blue);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.alert_list_navigation button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.alert_list_navigation button:hover:not(:disabled) {
  background-color: var(--primary-blue);
}

@media (max-width: 1000px) {
  .card_devices {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}
