.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(145deg, #1c1c1c, #001d42);
  border-radius: 1rem;
  padding: 1.5rem;
  width: 100%;
  color: #fff;
  text-align: center;
  height: 100%;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.status {
  background-color: #2aeab8;
  color: #333;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem 2.5rem;
  border-radius: 3rem;
  margin: 0.75rem 0;
}

.expiry,
.paymentType {
  font-size: 0.9rem;
  margin: 0.3rem 0;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.75rem;
}

.button {
  padding: 0.75rem 2.5rem;
  border: 2px solid #364ab2;
  border-radius: 0.5rem;
  background: transparent;
  color: #364ab2;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.button:hover {
  background-color: rgba(54, 74, 178, 0.1);
  border: 2px solid #ededed;
  color: #ededed;
}
