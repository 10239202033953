.App {
  display: flex;
  flex-direction: row;
}

.AppNav {
  width: 100px;
}

.AppContent {
  width: 100%;
}