.central_Content {
    padding-left: 5rem;
    display: flex;
    flex-direction: column;
}

.dashboard {
    display: flex;
    height: 100vh;
}

.dashboard_Left {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    flex-direction: column;
}

.dashboard_Right {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

@media only screen and (max-width: 768px) {
    .central_Content {
        padding: 0rem;
    }
    .dashboard_Right {
        height: 89.5vh;
    }
}