:root {
    --primary-color: #25347B;
    --secondary-color: #FEFEFE; 
    --font-color: #121212;
    --primary-gray: #909090;
    --alert-color: #C2C53C;
    --alert-panic: #BB3838;
    --alert-light: #76A2AF;
}

:root {
  --primary-white: #FEFEFE;
  --primary-black: #121212;
  --primary-gray: #E5E5E5;
  --primary-blue: #2a2a2a;
  --secondary-blue: #100F17;
  --central-background: #E5E5E5;
  --primary-font-colour: #121212;
}

  