.inferences_Content {
  display: flex;
  padding: 1em;
  width: 100%;
  background-color: #1c1c1c;
  color: #fefefe;
  padding: 2rem;
  border-radius: 1rem;
}

.bar_Chart {
  height: 450px;
  width: 100%;
}

.pie_Chart {
  background-color: #1c1c1c;
  color: #fefefe;
  padding: 2rem;
  border-radius: 1rem;

  width: 100%;
}

.pie_Chart canvas,
.bar_Chart canvas {
  width: 80% !important;
}

.pie_Chart > p,
.bar_Chart > p {
  background-color: #242424;
  padding: 0.5rem;
  border-radius: 1rem;
  width: 40%;
}

.infereces_graphics_header {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.group_select select {
  background-color: #2c2c2c;
  color: #ffffff;
  border: 1px solid #444;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}

.group_select select:hover {
  background-color: #3a3a3a;
}

.group_select select:focus {
  border-color: #66afe9;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.8);
}

@media (max-width: 1000px) {
  .inferences_Content {
    padding: 0;
    flex-wrap: wrap;
  }
  .pie_Chart,
  .bar_Chart {
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin-bottom: 0.2rem;
  }
}
