.profile {
    padding-left: 6.5rem;
    padding-right: 1rem;
    background-color: #F9FAFB;
    height: 100vh;
}

.profile_Content {
    display: flex;
    padding-top: 1rem;
    flex-direction: column;
}

.profile_Header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.profile_Info > h2 {
    font-weight: 500;
    line-height: 0rem;
}

.profile_Info > p {
    filter: opacity(0.5);
}

.profile_Buttons {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.profile_Save {
    display: flex;
    align-items: center;
    border: none;
    padding: 1rem;
    border-radius: 0.5rem;
    color: #FEFEFE;
    border: 1px solid var(--primary-blue);
    width: 5rem;
    cursor: pointer;
    justify-content: center;
    background-color: var(--primary-blue);
}

.profile_Cancel {
    display: flex;
    justify-content: center;
    width: 5rem;
    cursor: pointer;
    background-color: #FFF;
    align-items: center;
    border: 1px solid #D0D5DD;
    padding: 1rem;
    border-radius: 0.5rem;
}

.profile_Name, .profile_Email, .profile_Pic {
    display: flex;
    border-top: 1px solid #EAECF0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-items: center;
    justify-content: space-between;
}

.profile_Inputs {
    display: flex;
    gap: 1rem;
}

.profile_Photo {
    display: flex;
    height: 10rem; /* Aumenta o tamanho do círculo da foto */
    width: 10rem; /* Aumenta o tamanho do círculo da foto */
    background-color: var(--primary-blue);
    border-radius: 50%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 1.5rem; /* Espaçamento inferior para separar dos campos de entrada */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Sombra para destacar a imagem */
    background-color: #EAECF0;
}

.profile_Photo img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover; /* Garante que a imagem cobre todo o círculo */
    border-radius: 50%; /* Mantém o círculo mesmo com a imagem retangular */
}

.profile_Inputs > input {
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #D0D5DD;
}

.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .profile {
        padding-left: 1rem;
    }

    .profile_Inputs {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .profile_Header {
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;
    }
    
    .profile_Buttons {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .profile_Name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .profile_Email {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .profile_Photo {
        height: 8rem; /* Ajusta o tamanho para dispositivos menores */
        width: 8rem; /* Ajusta o tamanho para dispositivos menores */
    }
}
